import { Anchor } from '@qogita/ui/anchor'
import { PageError } from '@qogita/ui/page-error'
import NextLink from 'next/link'

import { PublicLayout } from '#components/shared/templates/PublicLayout'
import { SupportEmailLink } from '#src/components/shared/EmailLinks'
import { Seo } from '#src/components/shared/Seo'
import { useUserContext } from '#src/contexts/User'
import { useLandingRoute } from '#src/hooks/utils/useLandingRoute'
import { Page } from '#src/types/next'

const Custom404: Page = () => {
  const landingRoute = useLandingRoute()
  const user = useUserContext()

  const pageErrorUrl = user ? landingRoute : '/'

  const returnHomeAnchor = <Anchor href="/">sellers.qogita.com</Anchor>

  return (
    <>
      <Seo title="Page not found" />

      <div className="my-[10vh]">
        <div className="flex justify-center">
          <NextLink href={pageErrorUrl}>
            <PageError.Logo />
          </NextLink>
        </div>

        <div className="text-center">
          <PageError.Title className="mb-2 mt-5">
            We can’t find this page
          </PageError.Title>

          <PageError.Code httpCode="404" className="mb-5" />

          <p className="mx-auto max-w-lg text-base">
            Click here to return to {returnHomeAnchor}. Please contact{' '}
            <SupportEmailLink /> if the issue continues.
          </p>
        </div>
      </div>
    </>
  )
}

Custom404.options = {
  public: true,
  getLayout: (page) => <PublicLayout pageBlok={null}>{page}</PublicLayout>,
}

export default Custom404
