import { Anchor } from '@qogita/ui/anchor'

import { EMAILS } from '#src/constants/copy'

export function SupportEmailLink() {
  return <Anchor href={`mailto:${EMAILS.support}`}>{EMAILS.support}</Anchor>
}

export function ProcurementEmailLink({
  children = EMAILS.procurement,
}: {
  children?: React.ReactNode
}) {
  return <Anchor href={`mailto:${EMAILS.procurement}`}>{children}</Anchor>
}
